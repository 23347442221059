import React from 'react';
import { useTranslation } from 'react-i18next';
import { Globe, Languages } from 'lucide-react';
import { Button } from "../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="relative inline-block text-lef className=' text-white">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button 
            variant="ghost" 
            size="icon" 
            className="h-9 w-9 rounded-full"
          >
            <Globe className="h-4 w-4" />
            <span className="sr-only">Changer de langue</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
        className="bg-white/ backdrop-blur-lg border-white/20"

        >
          <DropdownMenuItem
            onClick={() => changeLanguage('fr')}
            className="flex items-center cursor-pointer"
          >
            <Languages className="mr-2 h-4 w-4  text-white" />
            <span className=' text-white '>Français</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => changeLanguage('en')}
            className="flex items-center cursor-pointer"
          >
            <Languages className="mr-2 h-4 w-4 text-white" />
            <span className=' text-white '>English</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default LanguageSwitcher;