import React from "react";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../ui/select";
import { Input } from "../ui/input";
import { Switch } from "../ui/switch";
import { Currency, Errors } from "../../types/types";
import { useTranslation } from "react-i18next";


interface FormPropsType {
  currencies: Currency[];
  selectedCaption: string;
  backendUrlNet: any;
  amount: string;
  phone: string;
  transactionFee: number;
  errors: Errors;
  fees?: boolean;
  disabledInput?: boolean;
  handleSelect?: (value: string) => void;
  handleAmountChange: (value: string) => void;
  setPhone: (phone: string) => void;
  toggleSupportFrais: (checked: boolean) => void;
  title: string;
  disabledSelect?: boolean;
  placeholder?: string;
  selectEmpty?: React.ReactNode;
  amountEmpty?: React.ReactNode;
  phoneEmpty?: React.ReactNode;
  placeholderNumero: string;
  placeholderMontant: string;
  recentNumbers?: string[];
  quickAmounts?: string[];
}

const FormProps: React.FC<FormPropsType> = ({
  currencies,
  selectedCaption,
  amount,
  phone,
  transactionFee,
  errors,
  fees,
  disabledInput,
  handleSelect,
  handleAmountChange,
  setPhone,
  toggleSupportFrais,
  title,
  disabledSelect,
  placeholder,
  selectEmpty,
  amountEmpty,
  placeholderNumero,
  placeholderMontant,
  phoneEmpty,
}) => {
  const { t } = useTranslation();
  // Add debugging logs
  

  return (
    <div className="space-y-2 text-white">
      <label className="text-lg font-medium text-white/85">{title}</label>
      <div className="flex items-center space-x-2 sm:space-x-4">
        <div className={`flex-1 ${amountEmpty ? "mb-4" : ""}`}>
          <Select
            disabled={disabledSelect}
            onValueChange={handleSelect}
            value={selectedCaption}
          >
            <SelectTrigger className="w-full bg-white/15 border-none text-white/90">
              <SelectValue placeholder={placeholder}>
                {selectedCaption || "Select a caption"}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {currencies.map((currency) => {
                const metaData =
                  typeof currency.meta === "string"
                    ? JSON.parse(currency.meta)
                    : currency.meta;


                return (
                  <SelectItem key={currency.id} value={currency.caption}>
                    <div className="flex items-center space-x-2 text-white">
                      <span>
                        {metaData?.from_service_name
                          ? `${metaData.from_service_name} vers ${metaData.to_service_name}`
                          : "Service names not available"}
                      </span>
                    </div>
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
          {selectEmpty}
        </div>
        <div className="flex-1">
          <Input
            disabled={!disabledInput}
            type="number"
            value={amount}
            onChange={(e) => handleAmountChange(e.target.value)}
            className={`w-full bg-white/15 border-none placeholder:text-gray-300 ${
              errors.amount ? "border-red-800 focus:border-red-800" : ""
            } ${selectEmpty ? "mb-4" : ""}`}
            placeholder={placeholderMontant}
          />
          {amountEmpty}
          {/* Quick Amount Buttons */}
        </div>
      </div>
      {/* Phone Input with Recent Numbers */}

      <div className="flex items-center space-x-2 sm:space-x-4">
        <div className="relative flex-1">
          <Input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className={`w-full bg-white/15 border-none  placeholder:text-gray-300  ${
              errors.fromPhone ? "border-red-500 focus:border-red-500" : ""
            }`}
            placeholder={placeholderNumero}
          />
          {phoneEmpty}
          {/* <div className="absolute right-2 top-1/2 -translate-y-1/2">
          <PhoneFavorites
            currentPhone={phone}
            onSelectContact={(phone) => setPhone(phone)}
            currency={selectedCaption}
          />
        </div> */}
        </div>
      </div>

      {/* Fees Section */}
      <div className="flex items-center justify-between px-2 py-1 bg-white/10 rounded-lg">
        <span className="text-sm text-white/55">
          {t("supportFees")} ({transactionFee} FCFA)
        </span>
        <Switch
          checked={fees}
          onCheckedChange={toggleSupportFrais}
          className="bg-white/10 data-[state=checked]:bg-pink-500/80 focus-visible:ring-0 focus-visible:ring-offset-0"
        />
      </div>
      {fees && amount && (
        <div className="text-sm text-white/50 mt-1">
          {t("totalWithFees")}:{" "}
          {(parseFloat(amount) + transactionFee).toFixed(2)} FCFA
        </div>
      )}
    </div>
  );
};

export default FormProps;
