import SwapInterface from "./module/SwapInterface";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "./components/props/theme-provider";
import { ThemeSwitcher } from "./components/props/ThemeSwitcher";
import LanguageSwitcher from "./components/LanguageSwitcher";
import WelcomePage from "./module/WelcomePage";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import { TooltipProvider } from "./components/ui/tooltip";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ThemeProvider defaultTheme="light" storageKey="swap-theme-preference">
        <Router>
          <div className="relative min-h-screen bg-gradient-to-br from-[hsl(var(--gradient-start))] to-[hsl(var(--gradient-end))]">
            {/* Position the theme switcher */}
            <div className="absolute top-4 right-4 z-50">
              {/* <ThemeSwitcher /> */}
              <LanguageSwitcher />
            </div>

            <Routes>
              <Route path="/" element={<WelcomePage />} />
              <Route
                path="/home"
                element={
                  <ProtectedRoute>
                    <SwapInterface />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
