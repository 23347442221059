import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../contexts/AuthContext";
import TelegramLoginButton from "../components/ui/TelegramLoginButton";
import { Loader2 } from "lucide-react";
import { TelegramAuthData } from "../services/authService";
import axios from "axios";
import TelegramStickerWelcome from "../components/ui/TelegramStickerWelcome";
import stickerWelcome from "../../src/assets/sticker/stickerWelcome.json";
import stickerAuth from "../../src/assets/sticker/lock.json";
import TelegramStickerStart from "../components/ui/TelegramStickerStart";
interface TelegramUser {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
  photo_url?: string;
  auth_date: number;
  hash: string;
}
interface userData {
  telegramId: number;
  firstName: string;
  lastName: string;
}
const WelcomePage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { telegramAuth, isAuthenticated, isLoading, updateAuthState } =
    useAuth();
  const [authError, setAuthError] = useState<string | null>(null);
  const [isWebApp, setIsWebApp] = useState(false);
  const [telegramInitialized, setTelegramInitialized] = useState(false);
  const [userData, setUserData] = useState<userData | null>(null);
  const botName =
    process.env.REACT_APP_PRODUCTION === "1"
      ? process.env.REACT_APP_TELEGRAM_BOT_NAME_PROD
      : process.env.REACT_APP_TELEGRAM_BOT_NAME_TEST;

  const backendUrlNet =
    process.env.REACT_APP_TEST === "1"
      ? process.env.REACT_APP_BACKEND_URL_NGROK
      : process.env.REACT_APP_BACKEND_URL_NET;
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
    setUserData(storedUser);
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    const checkTelegramWebApp = async () => {
      const webApp = window.Telegram?.WebApp;
      setUserData(JSON.parse(localStorage.getItem("user") || "{}"));

      if (!webApp || !(webApp as any).initData) {
        setTelegramInitialized(true);
        setIsWebApp(false);
        return;
      }

      setIsWebApp(true);
      setTelegramInitialized(true);

      try {
        webApp.ready();
        const initData = (webApp as any).initData;
        if (!initData) {
          return;
        }

        const response = await axios.post(
          `${backendUrlNet}/api/auth/telegram-webapp`,
          { initData }
        );

        const { token, user } = response.data;
        updateAuthState(token.token, user);
        setUserData(user);
        localStorage.setItem(
          "user",
          JSON.stringify({
            telegramId: user.telegramId,
            firstName: user.firstName,
            lastName: user.lastName,
          })
        );
      } catch (error: any) {
        console.error("WebApp auth error:", error);
        setAuthError(error.response?.data?.error || t("loginError"));
      }
    };

    checkTelegramWebApp();
  }, [isAuthenticated, navigate, t, updateAuthState, backendUrlNet]);

  const handleTelegramLogin = async (telegramUser: TelegramUser) => {
    try {
      setAuthError(null);
      const authData: TelegramAuthData = {
        id: telegramUser.id.toString(),
        first_name: telegramUser.first_name,
        last_name: telegramUser.last_name,
        username: telegramUser.username,
        photo_url: telegramUser.photo_url,
        auth_date: telegramUser.auth_date,
        hash: telegramUser.hash,
      };

      const success: Boolean = await telegramAuth(authData);
      if (success) {
        const userData = {
          telegramId: parseInt(authData.id),
          firstName: authData.first_name,
          lastName: authData.last_name || "",
        };
        setUserData(userData);
        localStorage.setItem("user", JSON.stringify(userData));
        navigate("/home");
      } else {
        setAuthError(t("loginError"));
      }
    } catch (error: any) {
      console.error("Login error:", error);
      setAuthError(error.response?.data?.error || t("loginError"));
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-700 to-pink-600">
        <Loader2 className="h-8 w-8 animate-spin text-white" />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen justify-center items-center bg-gradient-to-br from-purple-700 to-pink-600">
      <div className="w-full max-w-md p-4 space-y-6">
        {/* Sticker and Title */}
        {isAuthenticated ? (
          // <div className="text-center">
          //   <div className="mb-8">
          //   <TelegramStickerWelcome />
          //   </div>
          //   <h1 className="text-2xl font-bold text-white mt-4">{t("welcome")}</h1>
          //   <p className="text-center text-white text-sm px-4">
          //       {t("description")}
          //   </p>

          // </div>
          <div className="text-center">
            <TelegramStickerWelcome stickerData={stickerWelcome} />
            <h1 className="text-2xl font-bold text-white  ">
              {t("welcome")}
            </h1>
            <p className="text-center text-white text-sm px-4 mt-8">
              {t("description")}
              <br />
            </p>
            <p className="text-blue-300 px-4 mt-3 font-bold">
              Hello {userData?.firstName} !
            </p>
          </div>
        ) : (
          <div className="text-center">
            <TelegramStickerWelcome stickerData={stickerAuth} />
            <h1 className="text-2xl font-bold text-white mb-6 ">
              {t("authentication")}
            </h1>
            <p className="text-center text-white text-sm px-4 mt-8">
              {t("description")}
              <br />
            </p>
            <p className="text-blue-300 px-4 mt-3 font-bold">
              {t("descriptionAuth")}
            </p>
          </div>
        )}
        {/* <div className="text-center">
          <TelegramSticker />
          <h1 className="text-2xl font-bold text-white mt-8">{t("welcome")}</h1>
        </div> */}

        {/* Description and Login/Start Button */}
        <div className="space-y-">
          {isAuthenticated ? (
            <div className="flex justify-center">
              <button
                onClick={() => navigate("/home")}
                className="px-[85px] py-3 text-white tracking-widest bg-[#4bafe1]  rounded-md hover:bg-purple-900 focus:outline-none telegram-login-button"
              >
                <TelegramStickerStart />
              </button>
            </div>
          ) : telegramInitialized && !isWebApp ? (
            <div className="flex justify-center bg">
              {botName ? (
                <TelegramLoginButton
                  botName={botName}
                  onAuth={handleTelegramLogin}
                  buttonSize="large"
                  cornerRadius={8}
                  requestAccess={true}
                />
              ) : (
                <div className="text-white bg-red-500/20 p-4 rounded-lg">
                  {t("telegramBotNotConfigured")}
                </div>
              )}
            </div>
          ) : !telegramInitialized ? (
            <div className="text-center text-white text-sm">
              {t("initializingTelegram")}
            </div>
          ) : null}
        </div>

        {/* Error Message */}
        {authError && (
          <div className="mt-4 p-3 bg-red-500/20 border border-red-500/50 rounded-lg">
            <p className="text-white text-center text-sm">{authError}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomePage;
