import React from "react";
import { ChevronDown, ChevronUp, Copy } from "lucide-react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible";
import { useTranslation } from "react-i18next";
import { Orders } from "@/types/types";

import { cn } from "../../utils/utils";

interface TransactionHistoryProps {
  orders: Orders[];
  isLoading: boolean;
  onSelectPhone?: (phone: string) => void;
}

const statusColors = {
  success: "text-green-500 bg-green-500/10",
  pending: "text-yellow-500 bg-yellow-500/10",
  failed: "text-red-500 bg-red-500/10",
};

const formatDate = (dateString?: string) => {
  if (!dateString) return "Date invalide";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.error("Date invalide :", dateString);
    return "Date invalide";
  }

  const formattedDate = date.toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${formattedDate}, ${formattedTime}`;
};

const TransactionHistory: React.FC<TransactionHistoryProps> = ({
  orders,
  isLoading,
  onSelectPhone,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();

  const getTransactionStatus = (order: Orders) => {
    if (
      order.status === "failed" ||
      (order.transactions[0].status === "failed" &&
        order.transactions[1].status === "failed")
    ) {
      return { text: t("failed"), style: statusColors.failed };
    }

    if (
      order.status === "success" ||
      (order.transactions[0].status === "success" &&
        order.transactions[1].status === "success")
    ) {
      return { text: t("success"), style: statusColors.success };
    }

    if (order.transactions[0].status === "pending") {
      return { text: t("retraitPending"), style: statusColors.pending };
    }
    if (order.transactions[0].status === "failed") {
      return { text: t("retraitFailed"), style: statusColors.failed };
    }
    


    if (
      order.transactions[0].status === "success" &&
      order.transactions[1].status === "pending"
    ) {
      return { text: t("depotPending"), style: statusColors.pending };
    }

    if (
      order.transactions[0].status === "success" &&
      order.transactions[1].status === "failed"
    ) {
      return { text: t("depotFailed"), style: statusColors.failed };
    }

    return { text: t("pending"), style: statusColors.pending };
  };

  const handleCopyPhone = (phone: string) => {
    navigator.clipboard.writeText(phone);
    // You could add a toast notification here
  };

  const handlePhoneClick = (phone: string) => {
    if (onSelectPhone) {
      onSelectPhone(phone);
      setIsOpen(false); // Close the history after selection
    }
  };

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="overflow-hidden"
    >
      <CollapsibleTrigger className="w-full px-4 mb-3 py-3 gap-2 flex items-center justify-center text-white hover:bg-white/5">
        <div className="flex items-center gap-1">
          <span className="font-medium text-lg">{t("titreHistory")}</span>
          <span className="text-xs text-white/70">({orders.length})</span>
        </div>
        {isOpen ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
      </CollapsibleTrigger>

      <CollapsibleContent>
        <div className="max-h-[50vh] overflow-y-auto px-2 py-2 text-white space-y-2">
          {isLoading ? (
            <div className="flex justify-center py-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
            </div>
          ) : orders.length === 0 ? (
            <div className="text-center py-4 text-white/60">
              {t("noTransactions")}
            </div>
          ) : (
            orders.map((order) => {
              const status = getTransactionStatus(order);
              return (
                <div
                  key={order.id}
                  className="p-3 bg-white/5 rounded-lg hover:bg-white/10 transition-colors"
                >
                  <div className="flex justify-between mx-1">
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <span>
                          {order.transactions[0].currency} →{" "}
                          {order.transactions[1].currency}
                        </span>
                        <span
                          className={cn(
                            "text-xs px-2 py-0.5 rounded-full",
                            status.style
                          )}
                        >
                          {status.text}
                        </span>
                      </div>

                      <div className="text-sm text-white/70">
                        {formatDate(order.createdAt)}
                      </div>

                      <div className="text-sm flex items-center gap-2">
                        <span className="text-white/60">{t("de")}</span>
                        <button
                          onClick={() =>
                            handlePhoneClick(
                              order.transactions[0].phoneNumber.toString()
                            )
                          }
                          className="hover:text-white/90 transition-colors flex items-center gap-1"
                        >
                          {order.transactions[0].phoneNumber}
                          <Copy
                            size={14}
                            className="opacity-50 hover:opacity-100"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopyPhone(
                                order.transactions[0].phoneNumber.toString()
                              );
                            }}
                          />
                        </button>
                        <span>→</span>
                        <span className="text-white/60">{t("vers")}</span>
                        <button
                          onClick={() =>
                            handlePhoneClick(
                              order.transactions[1].phoneNumber.toString()
                            )
                          }
                          className="hover:text-white/90 transition-colors flex items-center gap-1"
                        >
                          {order.transactions[1].phoneNumber}
                          <Copy
                            size={14}
                            className="opacity-50 hover:opacity-100"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopyPhone(
                                order.transactions[1].phoneNumber.toString()
                              );
                            }}
                          />
                        </button>
                      </div>

                      <div>
                        <span className="text-sm text-white/70">ID: </span>
                        <span className="text-sm">{order.orderCode}</span>
                      </div>
                    </div>

                    <div className="text-right">
                      <div className="font-medium">
                        {order.transactions[0].amount.toLocaleString()} FCFA
                      </div>
                      <div className="text-sm text-white/70">
                        →{order.transactions[1].amount.toLocaleString()} FCFA
                      </div>
                      <div className="text-xs text-white/60 mt-4">
                        {t("FraisFacture")}:{" "}
                        {order.transactions[0].debit === 1
                          ? t("destinataire")
                          : t("expéditeur")}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default TransactionHistory;
