import React from "react";
import Lottie from "react-lottie";

interface LottieStickerProps {
  stickerData: object; 
  height: number;
  width: number;
}

const LottieSticker: React.FC<LottieStickerProps> = ({ stickerData , height , width}) => {
  const defaultOptions = {
    loop: true,
    height: height,
    width: width,
    autoplay: true,
    animationData: stickerData, // Sticker dynamique
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
        <Lottie options={defaultOptions} height={height} width={width} />

    </div>
  );
};

export default LottieSticker;
